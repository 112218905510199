:root {
  
  /* Main Settings  */
  --main-background-color: rgb(255, 255, 255);

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(255, 255, 255);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: linear-gradient( 
    227.3deg
    , #8A208C 0%, #0225b8 100.84%);
  --nav-height: 100px;
  --nav-logo-width: 86px;
  --nav-logo-font-size: 18px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: #0225b8;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(255, 255, 255);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #212029;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#604ae5 0%,#813eee 100%);
  --minting-button-text-color: white;

}






