
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;600;700;800;900&display=swap');

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');
.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: radial-gradient(#3b213d 25%, #000000 30%);
  background-size: 40px 40px;
  -webkit-animation: bscale 100s infinite linear;
  -webkit-animation-animation: bscale 100s infinite linear; */
}

.special-background{
  align-items: center;
   background: radial-gradient(#3b213d 25%, #000000 30%);
  background-size: 40px 40px;
  -webkit-animation: bscale 100s infinite linear;
  -webkit-animation-animation: bscale 100s infinite linear; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  font-weight: 700;
  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo{
  width: 100px;
}



















.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 10px 0 10px;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
    
  }

  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.text-white-color {
  color: white;
}
.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}



@keyframes bscale {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 100% 100%;
  }
}
.gashapon-main {
  text-align: center;
}


.gallery-item {
  margin: 5px;
}


.gallery-container{
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
}
.gallery-container img{
  width: 150px;
  border-radius: 20px;
  padding: 3px;
}
@media only screen and (max-width: 958px) {
  .gallery-container img{
    width: 100px;
    border-radius: 20px;
    padding: 5px;
  }
}
















.RarityHeading {
  font-style: normal;
  display: flex;
  align-items:top;
  color: #090065;
}
.RarityList {
  font-weight: 700;
  padding: 0;
  list-style: none;
}
.RarityList li {
  display: flex;
  justify-content: space-between;
  line-height: 85px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 0.0625vw solid #ada7a96e;
}
@media only screen and (max-width: 958px) {
  .RarityList li {
    display: flex;
    justify-content: space-between;
    line-height: 25px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 0.0625vw solid #ada7a96e;
  }
}




.RarityList li span:first-of-type {
  display: flex;
  align-items: center;
  color: #090065;
}
.RarityList li span:last-of-type {

  display: flex;
  align-items:top;
  text-align: right;
  text-transform: uppercase;
  color: #7351ff;
}
.RarityImage img,
.RarityImagem img {
  display: block;
  width: 100%;
}

.MuiButton-root{
  color: white !important;
}


ol {
  padding-left: 20px;
}